import React from "react";
import LandingPage from "./zain-iraq/LandingPage";
import KorekSignup from "./korek/korek-signup";
// import KorekSignup from "./korek/korek-signup";

function Signup() {
  const hostname = window.location.hostname;
  const renderContent = () => {
    switch (hostname) {
      case "ziq.gamerclub.live":
        return <LandingPage />;
      case "korek.gamerclub.live":
        return <KorekSignup />;

      default:
        return <LandingPage />;
    }
  };
  return <>{renderContent()}</>;
}

export default Signup;
