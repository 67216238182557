import React from "react";
import { Routes, Route, redirect, Navigate } from "react-router-dom";

import FailPageKorek from "../pages/korek/korek-fail";
import KorekVerify from "../pages/korek/korek-verify";
import SuccessKorek from "../pages/korek/korek-success";
import Signup from "../pages/signup";
import FailPage from "../pages/zain-iraq/FailPage";
import PinCodePage from "../pages/zain-iraq/PinCodePage";
import ThankYouPage from "../pages/zain-iraq//ThankyouPage";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/signup" />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/proceeding" element={<Signup />} />
      <Route path="/fail" element={<FailPage />} />
      <Route path="/verify" element={<PinCodePage />} />
      <Route path="/success" element={<ThankYouPage />} />
      <Route path="/korek-fail" element={<FailPageKorek />} />
      <Route path="/korek-verify" element={<KorekVerify />} />
      <Route path="/korek-success" element={<SuccessKorek />} />
    </Routes>
  );
};

export default AppRouter;
