import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./router/router";

import "./scss/main.css";

function App() {
  localStorage.setItem('i18nextLng','en');
	let langvar = localStorage.getItem('i18nextLng');
	if(langvar === "en-US"){
		localStorage.setItem('i18nextLng','en');
	}
  return (
    <>
      <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <AppRouter />
      </Router>
    </>
  );
}

export default App;
