import React from 'react'
import headerBg from "../assets/imgs/Asset 3.svg";
import headerBg2 from "../assets/imgs/Asset 2.svg";
import bodyBg from "../assets/imgs/Asset 6.svg";

import bodyBg2 from "../assets/imgs/Assetm.svg";
import logo from "../assets/imgs/logo2.32a2f0e40fc629cb6e51.png";
import logo2 from "../assets/imgs/logo2.png";
import LanguageSelector from './Language-selector';

function Layout(props) {
    return (
      <div className="main-layout container h-100">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-6 col-md-8 position-relative">

      <LanguageSelector />

          <div className="d-flex justify-content-center align-items-center">
            <div className="serviceTitle flex-grow-1">
              <img className="servicebg" src={headerBg} alt="" />
              <h1 className="serviceName ">
                <img className="w-50" src={logo} alt="" />
              </h1>
            </div>
            <div className="serviceTitle">
              <div className="cycleBorder">
                <img className="servicebg " src={headerBg2} alt="" />
              </div>
              <h1 className="serviceName">
                <img className="w-50" src={logo2} alt="" />
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8 col-md-12 position-relative">
         
          <picture>
            <source srcSet={bodyBg2} media="(max-width: 768px)" />
            <source srcSet={bodyBg} media="(min-width: 769px)" />
            <img className="w-100" src={bodyBg} alt="Responsive Banner" />
          </picture>
          <div className="body-details gap-lg-3 gap-0">
            {props.children}
          
          </div>
        </div>

      </div>
    </div>
    )
}

export default Layout
