import axios from "axios";

const base_url = "https://vas1.grand-hub.com/api/";

const axiosInstance = axios.create({
  baseURL: base_url, // Replace with your API base URL

  headers: { "Content-Type": "application/json" },
}); // Create the axios instance

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before the request is sent

    const { protocol } = window.location;

    //   Check if API URL should be HTTP or HTTPS
    // if (protocol === "https:") {
    //   console.log("https");
    //   if (!config.url.startsWith("https")) {
    //     config.url = config.url.replace(/^http:/, "https:");
    //   }
    // } else if (protocol === "http:") {
    //   console.log("Interceptor using: http");

    //   if (!config.url.startsWith("http:")) {
    //     config.url = config.url.replace(/^https:/, "http:");
    //     console.log(config.url, "url");
    //   }
    // }
    return config;
  },
  function (error) {
    // Handle the error
    return Promise.reject(error);
  }
);

// export default axiosInstance;

export { axiosInstance as axios };
