import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import logo from "./../../assets/imgs/check.png";

import { checkout } from "./../../Utilies/korek-api";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { gtagReportConversion } from "./../../Utilies/helper";
import Layout from "./../../component/layout";

export default function SuccessKorek() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  // Convert searchParams to an object
  const params = Object.fromEntries(searchParams.entries());
  let trafficsource;
  let parameter;

  useEffect(() => {
    getMarktingParamsFromLocalStorage();
    check_out();
  });
  const check_out = async () => {
    if (Object.keys(params).length !== 0) {
      try {
        const res = await checkout({ ...params, trafficsource, parameter });
        if (res.success === true) {
          gtagReportConversion();
        } else {
          navigate("/korek-fail");
        }
      } catch (error) {
        navigate("/korek-fail");
      }
    }
  };

  const getMarktingParamsFromLocalStorage = () => {
    trafficsource = localStorage.getItem("trafficsource");
    parameter = localStorage.getItem("parameter");
  };

  return (
    <>
      <Layout>
        <img className="servImg mb-5" src={logo} alt="" />

        <h4>{t("thankYouText")}</h4>
      </Layout>
    </>
  );
}
