export const gtagReportConversion = () => {
  const trackerKey = localStorage.getItem("parameter");
  if (trackerKey !== null && localStorage.getItem(trackerKey) !== "1") {
    window.gtag("event", "conversion", {
      send_to: "AW-16646980382/iigcCJ6GvuIZEJ6G84E-",
    });
    localStorage.setItem(trackerKey, "1");
  }
  return false;
};
export const handlePhoneNumber = (phone, countryKey) => {
  let phoneNumber = phone.toString().startsWith(countryKey)
    ? `${phone}`
    : `${countryKey}${phone}`;
  return phoneNumber;
};
